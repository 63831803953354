// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bK_k4";
export var caseStudyBackground__bgColor = "bK_kV";
export var caseStudyBackground__lineColor = "bK_kW";
export var caseStudyHead__imageAbsolute = "bK_k9";
export var caseStudyHead__imageWrapper = "bK_kT";
export var caseStudyProjectsSection = "bK_k5";
export var caseStudyQuote__bgRing = "bK_k1";
export var caseStudyTech = "bK_k3";
export var caseStudyVideo = "bK_k7";
export var caseStudyVideo__ring = "bK_k8";
export var caseStudy__bgDark = "bK_kS";
export var caseStudy__bgLight = "bK_kR";